<template>
  <router-link v-if="isInternalRoute" :to="href" @click="handleClick($event)">
    <v-btn :class="buttonClass" color="btnPrimary">{{ text }}</v-btn>
  </router-link>
  <a v-else-if="isGenericButton" rel="noopener noreferrer" @click="handleClick($event)">
    <v-btn :class="buttonClass" color="btnPrimary">{{ text }}</v-btn>
  </a>
  <a v-else-if="isExternalRoute"><v-btn :class="buttonClass" color="btnPrimary">{{ text }}</v-btn></a>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'

const emit = defineEmits(['click'])

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  href: {
    type: String,
    required: true
  },
  refType: {
    type: String,
    required: true
  },
  size: {
    type: String,
    required: false
  }
})

const isInternalRoute = computed(() => props.refType === 'internalRoute')
const isExternalRoute = computed(() => props.refType === 'externalRoute')
const isGenericButton = computed(() => props.refType === 'genericButton')
const buttonClass = computed(() => props.size === 'small' ? 'custom-button-small' : 'custom-button')

const handleClick = (event: any) => {
  emit('click')
  event.stopPropagation()
}
</script>

<style scoped>
.custom-button {
  border-radius: 59px !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin: 4px 0;
  min-width: 320px;
}
.custom-button-small {
  border-radius: 59px !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin: 4px 0;
  min-width: 200px;
}
</style>
