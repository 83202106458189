<template>
  <div class="home">

    <v-row>
      <v-col :cols="columnSizes?.[0]">
        <h1 class="my-4" :class="textClass?.h1">Logga in till mina sidor</h1>
        <p class="pb-4">
          Detta är en endast en demo och inga faktiska personuppgifter hämtas. I
          produktionmiljöer så inhämtas data från Skatteverket, Bolagsverket
          samt Mina Ombud.
        </p>
        <signin-overlay />
      </v-col>
      <v-col :cols="columnSizes?.[1]"></v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import signinOverlay from '@/components/signin-overlay.vue'

document.title = 'Logga in'

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  }
})

</script>
