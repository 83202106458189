<template>
  <v-app-bar
    app
    flat
    absolute
    color="primary"
    density="compact"
    height="100"
    :class="
      getHeaderWidth.value <= themedSettings.isMobileViewWidth
        ? 'xpadding-mobile'
        : getHeaderWidth.value < themedSettings.maxWidth
        ? 'xpadding-other'
        : 'xpadding-responsive'
    "
  >
    <img src="@/assets/logo_pos.svg" alt="Logo" class="logo" />
    <v-spacer></v-spacer>
    <div
      v-if="
        AuthStore.isAuthenticated &&
        getHeaderWidth.value > themedSettings.isMobileViewWidth
      "
      class="button-container"
    >
      <v-toolbar-items v-for="item in navigationItems" :key="item.title" link>
        <router-link :to="item.href">
          <v-btn flat class="pa-4 rounded custom-button" color="btnPrimary">{{ item.title }}</v-btn>
        </router-link>
      </v-toolbar-items>
    </div>
    <v-app-bar-nav-icon
      @click="toggleDrawer"
      v-if="getHeaderWidth.value <= themedSettings.isMobileViewWidth && AuthStore.isAuthenticated"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import { useDisplay } from 'vuetify'
import { themedSettings } from '@/plugins/vuetify'
import { useAuthStore } from '@/stores/authStore'
import { NavigationItem } from '@/types/types'

const AuthStore = useAuthStore()
const display = useDisplay()

const props = defineProps({
  navigationItems: Array as () => NavigationItem[],
  drawer: Boolean
})
const emit = defineEmits(['update:drawer'])

const getHeaderWidth = computed(() => {
  return display.width
})

const toggleDrawer = () => {
  emit('update:drawer', !props.drawer)
}
</script>

<style scoped>
.logo {
  max-width: 100px;
  max-height: 80px;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.custom-button {
  text-transform: none !important;
  color: #ffffff !important;
}
</style>
