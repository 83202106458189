<template>
  <div id="footer">
    <v-footer
      app
      flat
      absolute
      color="secondary"
      density="compact"
      height="230"
      :class="
        getFooterWidth.value <= themedSettings.isMobileViewWidth
          ? 'xpadding-mobile'
          : getFooterWidth.value < themedSettings.maxWidth
          ? 'xpadding-other'
          : 'xpadding-responsive'
      "
    >
      Adress: <br />Leverantörsvägen 2
      <br />
      187 66 Täby
      <v-spacer />Kontakt: <br />info@westro.se<v-spacer
    /></v-footer>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
import { themedSettings } from '@/plugins/vuetify'

const display = useDisplay()
const getFooterWidth = computed(() => {
  return display.width
})
</script>

<style scoped>
#footer {
  border-style: dashed;
  border: 1px solid red;
}
</style>
