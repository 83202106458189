<template>
  <v-toolbar app flat color="secondary" density="compact" height="60" id="statusNav">
    <router-link to="/" class="link"><v-btn plain prepend-icon="mdi-arrow-left" class="text-subtitle-2 ml-0 px-2"
        @click="AuthStore.setRepresentative('', '')"><u>Byt vem du vill företräda</u></v-btn></router-link>
    <div class="text-subtitle-2 font-weight-bold px-2">Du företräder:</div>
    <div class="text-subtitle-2">
      {{ AuthStore.getRepresentativeObject.name ? AuthStore.getRepresentativeObject.name : 'Ej möjligt att hämta namn'
      }}
    </div>
    <v-spacer />
    <v-avatar color="white" size="28" class="ma-2 text-body-2">{{
          AuthStore.getUserInitials
        }}</v-avatar>
    <div class="text-subtitle-2 pr-6">
      <strong> Inloggad som: {{ AuthStore.getFirstName }}</strong>
    </div>
    <v-btn plain class="text-subtitle-2 mr-2 px-2" @click="logoutRedirect"><u>Logga ut</u></v-btn>
  </v-toolbar>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore'

const AuthStore = useAuthStore()

function logoutRedirect () {
  AuthStore.isAuthenticated = false
}
</script>

<style scoped>
.link {
  text-decoration: none;
  color: inherit;
}
</style>
