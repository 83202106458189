// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Custom theme
const customTheme = {
  dark: false,
  colors: {
    primary: '#606f7c',
    secondary: '#dae6ee',
    btnPrimary: '#4684a9',
    info: '#e2e6e9',
    infoAlternative: '#ffcd9b',
    shadedBackground: '#dae2e6'
  },
  isMobileViewWidth: 1000
}

export default createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme
    }
  },
  components,
  directives
})

export const isMobileViewWidth = customTheme.isMobileViewWidth
export const themedSettings = {
  isMobileViewWidth: customTheme.isMobileViewWidth,
  maxWidth: 1800
}
export const colors = customTheme.colors
