<template>
  <h2 :class="textClass?.h2">Mina tjänster</h2>
  <div id="startpage-services" class="py-4">
    <div v-if="servicesStore.getStartpageSubset.length > 0" class="mb-4">
      <template v-for="item in servicesStore.getStartpageSubset" :key="item.key">
        <v-hover v-slot="{ isHovering, props }">
          <v-card flat :class="{ 'on-hover': isHovering }" :style="{
    '--v-hover-opacity': isHovering ? '0.025' : '0',
    'max-width': '750px',
  }" v-bind="props" @click="setServiceID(item)"><v-card-item class="py-2 px-0">
              <v-card-text class="pa-0 ma-0 d-flex justify-even-spaceing">
                <h3>{{ item.category }}</h3>
              </v-card-text>
              <v-card-text class="pa-0 ma-0">
                <strong>Nästa tömning:</strong>
                {{ item.closestStartDateString }}
              </v-card-text>
              <template v-slot:append>
                <div class="justify-self-end pe-5">
                  <v-icon color="primary" icon="mdi-arrow-right"></v-icon>
                </div>
              </template> </v-card-item></v-card>
        </v-hover>
      </template>
      <div class="pt-4">
        <customButton :text="'Alla tjänster & abonnemang'" :href="'/case'" :ref-type="'internalRoute'"></customButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import router from '@/router'
import { useServicesStore } from '@/stores/serviceStore'
import customButton from '@/components/ui/custom-button.vue'

const servicesStore = useServicesStore()

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  }
})

function setServiceID (item: any) {
  servicesStore.setServiceID(item.key)
  router.push({ path: `/service/${item.key}` })
}
</script>
