<template>
  <div v-if="!AuthStore.isAuthenticated">
    <LoginView :textClass="props.textClass" :columnSizes="columnSizes" />
  </div>
  <div v-else-if="!AuthStore.chosenRepresentative">
    <LandingView :textClass="props.textClass" :columnSizes="columnSizes" />
  </div>
  <div v-else>
    <StartpageView :textClass="props.textClass" :columnSizes="columnSizes" :paddingClass="paddingClass" />
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import LoginView from '@/views/LoginView.vue'
import LandingView from '@/views/LandingView.vue'
import StartpageView from '@/views/StartpageView.vue'

import { useAuthStore } from '@/stores/authStore'

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  },
  paddingClass: {
    type: String
  }
})

const AuthStore = useAuthStore()
</script>

<style scoped>
.noxpadding {
  padding-left: 0px !important;
}
</style>
