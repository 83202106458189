<template>
  <div id="startpage" class="py-4">
    <h1 :class="textClass?.h1">
      Hej, {{ AuthStore.getFirstName }}!</h1>
    <p>
      Nedan visas exempelvis ärenden och tjänster som är relevanta
    </p>
    <v-row class="pt-4">
      <v-col :cols="columnSizes?.[0]">
        <startpageCases :textClass="textClass" />
        <startpageServices :textClass="textClass" />
      </v-col>
      <v-col :cols="columnSizes?.[1]">
        <v-container class="bg-info pa-4">
          <h3 class="text-v-h3">Vi önskar alltid dina idéer!</h3>
          <p>
            Mina sidor är under ständig utveckling och förfining. Vi vill såklart alltid ha dina idéer samt information om fel uppstår, maila <a href="mailto:rikard@westro.se">rikard@westro.se</a>
          </p>
          <h3 class="text-v-h3 mt-4">Den här rutan</h3>
          <p>
            Här får du hjälp, tips och information relaterat till den sida du besöker.
          </p>
          <h3 class="text-v-h3 mt-4">Aktuellt för ditt företag
          </h3>
          <p>Ditt företags aktuella ärenden och händelser visas här.
          </p>
          <h3 class="text-v-h3 mt-4">Vad behöver du idag?</h3>
          <p>
            Här visas snabblänkar till funktioner och tjänster som kan vara intressanta för ditt företag.
          </p>
          <h3 class="text-v-h3 mt-4">Företaget</h3>
          <p>
            Här finns snabblänkar till sidor med samlad information om ditt företag
          </p>
        </v-container></v-col>
    </v-row>
  </div>

  <startpageLinks :textClass="textClass" :paddingClass="paddingClass" />
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useAuthStore } from '@/stores/authStore'

import startpageCases from '@/components/startpage/startpageCases.vue'
import startpageServices from '@/components/startpage/startpageServices.vue'
import startpageLinks from '@/components/startpage/startpageLinks.vue'

const AuthStore = useAuthStore()

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  },
  paddingClass: {
    type: String
  }
})
</script>
