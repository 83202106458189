<template>
  <div id="header">
    <header-appBar v-model:navigationItems="navigationItems" v-model:drawer="drawer" />
    <header-NavDrawer v-model:navigationItems="navigationItems" v-model:drawer="drawer" />
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue'
import HeaderAppBar from '@/components/ui/header/header-appBar.vue'
import headerNavDrawer from '@/components/ui/header/header-navDrawer.vue'

const drawer = ref(false)

const navigationItems = [
  { title: 'Startsida', href: '/' },
  { title: 'Ärenden', href: '/case' },
  { title: 'Beslut & Tillstånd', href: '/permits' },
  { title: 'Ombud', href: '/delegate' },
  { title: 'Företagsuppgifter', href: '/companyinfo' },
  { title: 'Fastigheter', href: '/estates' }
]
</script>
