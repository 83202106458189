<template>
  <customButton
    text="Logga in"
    href="/"
    refType="genericButton"
    size="small"
    @click="overlay = !overlay"
    class="my-4"
  ></customButton>
  <v-overlay v-model="overlay" contained class="align-center justify-center">
    <v-card class="pa-2">
      <v-form class="pa-8">
        <v-text-field
          v-model="firstName"
          label="Förnamn"
          required
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          label="Efternamn"
          required
        ></v-text-field>
        <customButton
          text="Logga in"
          href="/"
          refType="genericButton"
          size="small"
            @click="saveAndClose"
        ></customButton>
      </v-form>
    </v-card>
  </v-overlay>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import customButton from '@/components/ui/custom-button.vue'
import { useAuthStore } from '@/stores/authStore'

const AuthStore = useAuthStore()

const overlay = ref(false)
const firstName = ref('')
const lastName = ref('')

function saveAndClose () {
  AuthStore.setUserInfo({
    firstName: firstName,
    lastName: lastName
  })
  overlay.value = false
  AuthStore.setIsAuthenticated(true)
}
</script>
