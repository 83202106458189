<template>
  <div id="footer">
    <v-footer flat app absolute class="px-0 py-8">
      <v-container fluid :class="paddingClass" class="bg-secondary">
        <v-row>
          <v-col :cols="columnSizes[0]" class="primaryRow pr-12">
            <h2 :class="textClass?.h2">Vad behöver du idag?</h2>
            <!-- <customButton :text="'Hantera ombud'" :href="'/delegate'" :ref-type="'internalRoute'"></customButton> -->
            <br />
            <!-- <customButton
            :text="'Betalningar'"
            :href="'/'"
            :ref-type="'internalRoute'"
          ></customButton>
          <br /> -->
            <customButton :text="'Beslut, lov & tillstånd'" :href="'/permits'" :ref-type="'internalRoute'" />
            <br />
            <customButton :text="'Ärenden & tjänster'" :href="'/case'" :ref-type="'internalRoute'" />
            <br />
            <br />
            <h3>Om företaget</h3>
            <p>Här hittar du information om ditt företag.</p>
            <customButton :text="'Företagsuppgifter'" :href="'/companyinfo'" :ref-type="'internalRoute'" />
            <br />
            <customButton :text="'Företagets fastigheter'" :href="'/companyinfo'" :ref-type="'internalRoute'" />
            <br />
          </v-col>
          <v-col :cols="columnSizes[1]" class="secondaryRow">
            <h2 :class="textClass?.h2">E-tjänster</h2>
            <p>
              Du hanterar dina e-tjänster och ansökningar i E-tjänsteportalen
              (öppnas i nytt fönster)
            </p>
            <br />
            <h3>Vanligaste e-tjänsterna</h3>
            <customButton :text="'Slamtömning'" :href="'https://westro.se'" :ref-type="'externalRoute'" />
            <br />
            <customButton :text="'Komplettera ärende'" :href="'https://westro.se'"
              :ref-type="'externalRoute'" />
            <br />
            <customButton :text="'Bygglov, marklov, rivningslov'" :href="'https://westro.se'"
              :ref-type="'externalRoute'" />
            <br />
            <customButton :text="'Anmälan om utebliven sophämtning'" :href="'https://westro.se'"
              :ref-type="'externalRoute'" />
            <br />
            <customButton :text="'Små avlopp - ansökan/anmälan'" :href="'https://westro.se'"
              :ref-type="'externalRoute'" />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import customButton from '@/components/ui/custom-button.vue'

const columnSizes = computed(() => {
  if (props.paddingClass !== 'xpadding-mobile') {
    return [5, 5]
  }
  return [12, 12] // Add a default return value here
})

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  },
  paddingClass: {
    type: String
  }
})
</script>
