<template>
  <v-app>
    <appHeader :class="getContentPaddingClass" />
    <appFooter />
    <v-main>
      <infoBar v-if="AuthStore.isAuthenticated &&
      AuthStore.chosenRepresentative &&
      getContentPaddingClass !== 'xpadding-mobile'
      " :class="getContentPaddingClass" />
      <!-- <breadcrumbBar
        v-if="AuthStore.isAuthenticated && AuthStore.chosenRepresentative" /> -->
      <router-view :class="getContentPaddingClass" :textClass="getTextClasses" :columnSizes="columnSizes"
        :paddingClass="getContentPaddingClass" />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useDisplay } from 'vuetify'
import { themedSettings } from '@/plugins/vuetify'
import { useAuthStore } from '@/stores/authStore'
import { useCaseStore } from './stores/caseStore'
import { useEstatesStore } from './stores/estateStore'
import { useServicesStore } from './stores/serviceStore'
import appHeader from '@/components/layout/app-header.vue'
import appFooter from '@/components/layout/app-footer.vue'
import infoBar from '@/components/ui/info-bar.vue'
import breadcrumbBar from '@/components/ui/breadcrumb-bar.vue'

console.log('initiating app as: ' + process.env.VUE_APP_PUBLISHED_SITE)
console.log('app envieronment: ' + process.env.NODE_ENV)

const AuthStore = useAuthStore()
const CaseStore = useCaseStore()
const EstateStore = useEstatesStore()
const ServiceStore = useServicesStore()
const display = useDisplay()

const getContentPaddingClass = computed(() => {
  if (display.width.value <= themedSettings.isMobileViewWidth) {
    return 'xpadding-mobile'
  } else if (display.width.value < themedSettings.maxWidth) {
    return 'xpadding-other'
  } else {
    return 'xpadding-responsive'
  }
})

const columnSizes = computed(() => {
  const { xs, sm, md } = useDisplay()
  return xs.value || sm.value || md.value ? [12, 12] : [8, 4]
})

const getTextClasses = computed(() => {
  if (display.width.value <= themedSettings.isMobileViewWidth) {
    return {
      h1: 'text-c-h2',
      h2: 'text-c-h3'
    }
  } else {
    return {
      h1: 'text-c-h1',
      h2: 'text-c-h2'
    }
  }
})

function checkForDemoMode () {
  if (process.env.VUE_APP_PUBLISHED_SITE === 'demo') {
    AuthStore.setDemoData()
    CaseStore.setDemoData()
    EstateStore.setDemoData()
    ServiceStore.setDemoData()
  }
}

onMounted(() => {
  document.documentElement.style.setProperty(
    '--max-width',
    themedSettings.maxWidth - 160 - 18 + 'px'
  )
  checkForDemoMode()
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-c-h1 {
  font-size: 40px !important;
  font-weight: 700 !important;
}

.text-c-h2 {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.text-c-h3 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.text-c-h4 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.xpadding-responsive {
  padding-left: calc((100% - var(--max-width)) / 2);
  padding-right: calc((100% - var(--max-width)) / 2);
}

.xpadding-mobile {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.xpadding-other {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.informationBox {
  background: rgb(var(--v-theme-info))
}

a,
RouterLink {
  color: black;
}
</style>
