/* eslint-disable no-unused-expressions */
import { defineStore } from 'pinia'
import { useEstatesStore } from '@/stores/estateStore'

interface ConsumptionHistory {
  BuildingConsumptionYear: string;
  Values: string[];
}

interface Service {
  designation?: string;
  consumptionHistory?: Array<ConsumptionHistory>;
  id: string;
  buildingID: string;
  description: string;
  isActive: string;
  startDate: string;
  stopDate: string;
  binType: {
    code: string;
    containerType: string;
    size: string;
    unit: string;
  };
  fee: {
    calculatedCost: string;
    code: string;
    designation: string;
    id: string;
    isCostCalculated: string;
    partProduct: string;
    partYearDescription: string;
    partYearEnds: string;
    partYearStarts: string;
    product: string;
    binType: string;
    changeFrequencyText: string;
    changeSizeOfBinText: string;
    description: string;
    wastePickupFrequency: string;
    wastePickupFrequencyCode: string;
    wastePickupsPerYear: string;
    wasteType: string;
  };
  nextWastePickup: string;
  numberOfBins: string;
  numberOfBinsAntl: string;
  wastePickupFrequency: string;
  wastePickupFrequencyCode: string;
  wastePickupsPerYear: string;
  wasteType: string;
  CustomCategory: string;
  CustomDateobject: {
    CustomStartDate: number;
    CustomStartDateString: string;
    CustomEndDate: number;
    CustomEndDateString: string;
  };
  CustomFrequency: string;
}

interface IdentityService {
  identity: string;
  list: Array<{
    ID: string;
    designation: string;
    services: Array<Service>;
  }>;
  retryCount: number;
  status: string;
}

interface DateObject {
  CustomStartDate: number;
  CustomStartDateString: string;
  CustomEndDate: number;
  CustomEndDateString: string;
}

interface NextPickup {
  date: DateObject;
  services: Array<Service>;
}

interface GroupedService {
  key: string;
  designation: string;
  category: string;
  product: string;
  count: number;
  closestStartDate: number;
  closestStartDateString: string;
  binCount: number;
  nextPickups: Array<NextPickup>;
  services: Array<Service>;
}

function formatVarmdoEstateString (input: string) {
  const searchTerm = 'värmdö '
  const searchTermLower = searchTerm.toLowerCase()
  const inputLower = input.toLowerCase()

  if (inputLower.startsWith(searchTermLower)) {
    const afterSearchTerm = input.substring(searchTerm.length)
    return (
      capitalizeFirstLetter(searchTerm) + capitalizeFirstLetter(afterSearchTerm)
    )
  } else {
    return capitalizeFirstLetter(input)
  }
}

function capitalizeFirstLetter (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function getDatestampTwoDaysFromNow () {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 2)

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const useServicesStore = defineStore('servicesStore', {
  state: () => ({
    chosenRepresentative: '',
    serviceID: '',
    estateID: '',
    serviceLists: [] as Array<any>,
    servicesLoadStatus: 'notLoaded',
    searchServices: ''
  }),
  getters: {
    getChosenServiceBySelectedID (): GroupedService | undefined {
      return this.getServicesGroupedByDesignationAndCategory.find((service) => {
        return service.key === this.serviceID
      })
    },
    getServicesList (): Array<Service> {
      const listObjects: Array<Service> = []
      this.getCurrentRepresentaticeObject.list
        ? this.getCurrentRepresentaticeObject.list.forEach((building) => {
          building.services.forEach((service: Service) => {
            listObjects.push({
              designation: building.designation,
              ...service
            })
          })
        })
        : undefined
      return listObjects || []
    },
    getServicesGroupedByDesignationAndCategory (): Array<GroupedService> {
      const groupedServicesList: Array<GroupedService> = []
      this.getServicesList.forEach((service) => {
        const key = `${service.designation}_${service.CustomCategory}`.replace(
          /\s/,
          ''
        )
        const index = groupedServicesList.findIndex((item) => item.key === key)
        const receivedDate = service.CustomDateobject.CustomStartDate
        const receivedDateString = service.CustomDateobject.CustomStartDateString
        const binCount = parseInt(service.numberOfBins)

        if (index !== -1) {
          groupedServicesList[index].count += 1
          groupedServicesList[index].binCount += binCount

          const existingPickupDate = groupedServicesList[index].nextPickups
          const existingTimeObject = existingPickupDate.find((object) => {
            return (
              object.date.CustomStartDate === service.CustomDateobject.CustomStartDate &&
              object.date.CustomEndDate === service.CustomDateobject.CustomEndDate
            )
          })

          if (existingTimeObject !== undefined) {
            existingTimeObject.services.push(service)
          } else {
            groupedServicesList[index].nextPickups.push({
              date: service.CustomDateobject,
              services: [service]
            })
          }

          if (
            groupedServicesList[index].closestStartDate < receivedDate &&
            service.fee.product === 'RENH'
          ) {
            groupedServicesList[index].closestStartDate = receivedDate
            groupedServicesList[index].closestStartDateString =
              receivedDateString
          } else if (
            groupedServicesList[index].closestStartDate > receivedDate &&
            service.fee.product === 'VA'
          ) {
            groupedServicesList[index].closestStartDate = receivedDate
            groupedServicesList[index].closestStartDateString =
              receivedDateString
          }

          groupedServicesList[index].services.push(service)
        } else {
          groupedServicesList.push({
            key: key,
            designation: formatVarmdoEstateString(service.designation ?? ''),
            category: service.CustomCategory,
            product: service.fee.product,
            count: 1,
            closestStartDate: receivedDate,
            closestStartDateString: receivedDateString,
            nextPickups: [{ date: service.CustomDateobject, services: [service] }],
            binCount: binCount,
            services: [service]
          })
        }
      })

      return groupedServicesList
    },
    getCurrentRepresentaticeObject (): IdentityService {
      return (
        this.serviceLists.find(
          (service: IdentityService) =>
            service.identity === this.chosenRepresentative
        ) || ({} as IdentityService)
      )
    },
    getCurrentServicesList (): IdentityService {
      const estatesStore = useEstatesStore()
      const chosenRepresentative = estatesStore?.chosenRepresentative || ''
      return (
        this.serviceLists.find(
          (service: IdentityService) =>
            service.identity === chosenRepresentative
        ) || ({} as IdentityService)
      )
    },
    getStartpageSubset (): Array<GroupedService> {
      return this.getServicesGroupedByDesignationAndCategory !== undefined &&
        this.getServicesGroupedByDesignationAndCategory.length > 0
        ? this.getServicesGroupedByDesignationAndCategory
          .filter((service) => {
            return (
              service.closestStartDate !== 0 && service.product === 'RENH'
            )
          })
          .slice(0, 2)
        : []
    },
    getChosenCaseStatus (): string {
      const serviceObject = this.serviceLists.find(
        (c: IdentityService) => c.identity === this.chosenRepresentative
      )

      return serviceObject?.status || ''
    }
  },
  actions: {
    setDemoData () {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mockData = require(process.env.VUE_APP_MOCK_SERVICE)
      this.serviceLists = mockData
      this.servicesLoadStatus = 'loaded'
    },
    setServiceID (id: string) {
      this.serviceID = id
    },
    setRepresentative (representative: string) {
      this.chosenRepresentative = representative
    },
    updateServicesList (token: string, companies: string) {
      if (this.servicesLoadStatus === 'loaded') {
        return
      }
      const promises: any[] = []
      companies.split(',').forEach((company: any) => {
        const url = process.env.SERVICES_API_URL
        const headers = {
          identity: company,
          Authorization: `bearer ${token}`,
          'x-origin': process.env.ORIGIN_ENV as string
        }
        const promise = fetch(url, {
          method: 'GET',
          headers: headers
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.json()
          })
          .then((data) => {
            this.serviceLists.push({ identity: company, services: data })
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        this.servicesLoadStatus = 'loaded'
      })
    },
    setEstateID (id: string) {
      this.estateID = id.replace(/Värmdö /i, '')
    }
  },
  persist: {
    storage: sessionStorage
  }
})
