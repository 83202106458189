<template>
  <div>
    <h3>Företag som du kan företräda</h3>
    <h4 class="py-2">Organisationsnummer och namn</h4>
    <div
      v-for="company in listedCompanies"
      :key="company.organisationNumber"
      @click="
        setRepresentative(company.organisationNumber, company.type)
      "
    >
      <selectionCard
        :object="company"
        :first-row="['organisationNumber', 'name']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore'
import { useCaseStore } from '@/stores/caseStore'
import { useEstatesStore } from '@/stores/estateStore'
import { useServicesStore } from '@/stores/serviceStore'
import selectionCard from '@/components/ui/selection-card.vue'

const AuthStore = useAuthStore()
const CaseStore = useCaseStore()
const EstateStore = useEstatesStore()
const ServiceStore = useServicesStore()

const signerCompanies = AuthStore.getSignerCompanies
const delegates = AuthStore.getDelegates

const arraySources = [
  { type: 'company', source: signerCompanies },
  { type: 'delegateCompany', source: delegates }
]
const listedCompanies = arraySources.flatMap(({ type, source }) =>
  source.map((company) => ({
    organisationNumber: company.organisationNumber,
    name: company.name,
    type
  }))
)

function setRepresentative (organisationNumber: string, type: string) {
  AuthStore.setRepresentative(organisationNumber, type)
  CaseStore.setRepresentative(organisationNumber, type)
  EstateStore.setRepresentative(organisationNumber)
  ServiceStore.setRepresentative(organisationNumber)
}
</script>s
