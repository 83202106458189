<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-card flat :class="{ 'on-hover': isHovering && !disabled }" v-bind="props">
      <v-card-item class="py-2 px-0">
        <v-card-text class="pa-0 ma-0 d-flex justify-space-between">
          {{ firstRowText() }}
          <v-icon v-if="!disabled"
            class="ml-auto"
            >mdi-arrow-right</v-icon
          >
        </v-card-text>
      </v-card-item>
    </v-card>
  </v-hover>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  object: {
    type: Object
  },
  firstRow: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

function firstRowText () {
  let responseText = ''

  if (props.firstRow && props.firstRow.length > 0 && props.object) {
    props.firstRow.forEach((element: unknown) => {
      responseText += props.object?.[element as string] ?? ''
      if (props.firstRow.indexOf(element) !== props.firstRow.length - 1) {
        responseText += ' - '
      }
    })
  }

  return responseText
}
</script>

<style scoped>
.on-hover {
  background-color: rgba(245, 245, 245, 0.75);
  cursor: pointer;
}
</style>
