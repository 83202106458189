<template>
  <div class="py-2">
    <h3 class="py-2">Företag jag inte kan företräda</h3>
    <p class="maxWidthConstrained">
      Vi kan se att du har en roll i ett eller flera företag. För att du ska
      kunna se uppgifter och ärenden så behöver du som inte är firmatecknare bli
      tilldelad en fullmakt som ombud. Kontakta firmatecknaren för det företag
      som du vill representera och be denne anmäla fullmakt för dig i tjänsten
      Mina Ombud.
    </p>
    <h4 class="py-2">Organisationsnummer och namn</h4>
    <div
      v-for="company in nonSignerCompanies"
      :key="company.organisationNumber"
    >
      <selectionCard
        :object="company"
        :first-row="['organisationNumber', 'name']"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore'
import selectionCard from '@/components/ui/selection-card.vue'
import { watch } from 'vue'

const AuthStore = useAuthStore()

const nonSignerCompanies = AuthStore.getOtherCompanies
</script>

<style scoped>
.maxWidthConstrained {
  max-width: 85%;
}
</style>
