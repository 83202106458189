<template>
  <div id="landing" class="py-4">
    <h1 :class="textClass?.h1">Välkommen {{ AuthStore.getFirstName }}!</h1>
    <h2 :class="textClass?.h2" class="pb-6">
      Vem eller vad vill du företräda?
    </h2>
    <v-row>
      <v-col :cols="columnSizes?.[0]" class="pr-8">
        <div v-if="AuthStore.isAuthenticated &&
      AuthStore.permissionsLoadStatus === 'loading'
      ">
          <v-container fluid class="py-4">
            <v-row>
              <div class="welcome">
                Vi hämtar nu information kring vilka företag du får företräda..
                <v-progress-circular indeterminate color="primary" size="18"></v-progress-circular>
              </div>
            </v-row>
          </v-container>
        </div>
        <div v-if="AuthStore.isAuthenticated &&
      AuthStore.permissionsLoadStatus === 'loaded' &&
      AuthStore.getSignerCompanies.length + AuthStore.getDelegates.length >
      0
      ">
          <companieswithAccess />
        </div>
        <div v-if="AuthStore.isAuthenticated &&
      AuthStore.permissionsLoadStatus === 'loaded' &&
      AuthStore.getOtherCompanies.length > 0
      ">
          <companieswithoutAccess />
        </div>
      </v-col>
      <v-col :cols="columnSizes?.[1]">
        <div>
          <v-container fluid class="pa-7 informationBox" >
            <h3 class="text-v-h3">Välkommen till vår demo sida!</h3>
            <p>
              Det här är en demo sida för "Mina sidor" som är under utveckling.
              Vi vill gärna ha dina synpunkter och idéer. Maila till <a href="mailto:rikard@westro.se">rikard@westro.se</a>.
            </p>
            <br />
            <h3 class="text-v-h3">Vilket företag vill du företräda?</h3>
            <p>
              På den här sidan ser du vilka företag du kan företräda, som
              firmatecknare eller som ombud. Välj här vem du vill företräda
              idag.
            </p>
            <br />
            <h3 class="text-v-h3">Var hämtas informationen ifrån?</h3>
            <p>
              Uppgifterna om dig som privatperson hämtar vi från
              folkbokföringsdatabasen hos Skatteverket. För näringsidkare eller
              förening hämtar vi motsvarande uppgifter från Bolagsverkets
              register.
            </p>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useAuthStore } from '@/stores/authStore'
import companieswithAccess from '@/components/companies-withAccess.vue'
import companieswithoutAccess from '@/components/companies-withOutAccess.vue'

const AuthStore = useAuthStore()

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  }
})

document.title = 'Välkommen'
</script>
