<template>
  <h2 :class="textClass?.h2">Händelser i mina ärenden</h2>
  <div id="startpage-cases" class="py-4">
    <div v-if="CaseStore.caseLoadStatus === 'error'">
      Det uppstod ett fel när vi hämtade din informationen, vänligen
      försök igen senare.
    </div>
    <div v-else-if="CaseStore.caseLoadStatus !== 'loaded' &&
    CaseStore.caseLoadStatus !== 'loadedWithErrors'">
      <v-progress-circular indeterminate color="primary" size="18"></v-progress-circular>
      Laddar in senaste händelserna...
    </div>
    <div v-else-if="CaseStore.caseLoadStatus === 'loaded' &&
    CaseStore.getChosenCaseList.length === 0">
      Det finns inga händelser i dina ärenden, eller så har vi inte hittat några ärenden.
    </div>
    <div v-else>
      <template v-for="item in CaseStore.getStartpageSubset" :key="item.friendlyCaseID">
        <v-hover v-slot="{ isHovering, props }">
          <v-card flat :class="{ 'on-hover': isHovering }" :style="{
    '--v-hover-opacity': isHovering ? '0.025' : '0',
    'max-width': '750px',
  }" v-bind="props" @click="setCaseID(item)"><v-card-item class="py-2 px-0">
              <v-card-text class="pa-0 ma-0 d-flex justify-even-spaceing">
                <h3>{{ item.description }}</h3>
              </v-card-text>
              <v-card-text class="pa-0 ma-0">
                <strong>Status:</strong> {{ item.status }}
              </v-card-text>
              <v-card-text class="pa-0 ma-0">
                <strong>Uppdaterad:</strong> {{ item.updated }}
              </v-card-text>
              <template v-slot:append>
                <div class="justify-self-end pe-5">
                  <v-icon color="primary" icon="mdi-arrow-right"></v-icon>
                </div>
              </template> </v-card-item></v-card>
        </v-hover>
      </template>
      <div class="pt-4">
        <customButton :text="'Alla Ärenden & tjänster'" :href="'/case'" :ref-type="'internalRoute'"></customButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useCaseStore } from '@/stores/caseStore'
import router from '@/router'
import customButton from '@/components/ui/custom-button.vue'

const CaseStore = useCaseStore()

const props = defineProps({
  textClass: {
    type: Object
  },
  columnSizes: {
    type: Object
  }
})

function setCaseID (item: any) {
  CaseStore.setCaseID(item.friendlyCaseID)
  router.push({ path: `/case/${item.friendlyCaseID}` })
}
</script>
