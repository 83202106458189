import { defineStore } from 'pinia'
import { Company, Delegate } from '@/types/types'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    userInfo: {
      firstName: '',
      lastName: ''
    },
    token: '',
    accessToken: {
      lastRefresh: '',
      status: '',
      payload: '',
      authTag: ''
    },
    impersonating: '',
    chosenRepresentative: '',
    representativeType: '',
    representativeObjectType: '',
    permissionsLoadStatus: 'notLoaded',
    delegatesLoadStatus: 'notLoaded',
    person: {
      name: '',
      personalNumber: ''
    },
    children: [],
    companies: [],
    delegates: []
  }),
  actions: {
    setIsAuthenticated (value: boolean) {
      this.isAuthenticated = value
    },
    setUserInfo (value: any) {
      this.userInfo = value
      this.person.name = `${value.firstName.value} ${value.lastName.value}`
    },
    setDemoData () {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mockData = require(process.env.VUE_APP_MOCK_AUTH)
      this.companies = mockData.companies
      this.delegates = mockData.delegates
      this.permissionsLoadStatus = 'loaded'
      this.delegatesLoadStatus = 'loaded'
    },
    setRepresentative (id: string, objectType: string) {
      this.chosenRepresentative = id
      this.representativeObjectType = objectType
    }
  },
  getters: {
    getRepresentative (): string {
      return this.chosenRepresentative
    },
    getRepresentativeObject (): any {
      if (this.representativeObjectType === 'company') {
        return this.companies.find(
          (company: Company) =>
            company.organisationNumber === this.chosenRepresentative
        )
      } else if (this.representativeObjectType === 'delegateCompany') {
        return this.delegates.find(
          (delegate: Delegate) =>
            delegate.organisationNumber === this.chosenRepresentative
        )
      }
    },
    getUserInitials (): string {
      const { firstName, lastName } = this.userInfo
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
    },
    getFirstName (): string {
      const firstName = this.userInfo.firstName
      return (
        firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()
      )
    },
    getSignerCompanies (): Company[] {
      return this.companies.filter(
        (company: Company) =>
          company.role === 'Signer' && /(AB|HB)/.test(company.foretagsform)
      )
    },
    getOtherCompanies (): Company[] {
      return this.companies.filter(
        (company: Company) =>
          company.role !== 'Signer' && /(AB|HB)/.test(company.foretagsform)
      )
    },
    getDelegates (): Delegate[] {
      return this.delegates
    }
  },
  persist: {
    storage: sessionStorage
  }
})
