<template>
  <v-navigation-drawer v-model="drawer" app temporary location="right">
    <v-list v-if="AuthStore.isAuthenticated">
      <v-list-item v-for="item in navigationItems" :key="item.title" link @click="toggleDrawer">
        <router-link :to="item.href" class="custom-button"><v-list-item-title>{{ item.title }}</v-list-item-title></router-link>
      </v-list-item>
      <v-list-item link>
        <v-list-item-title @click="signOut">Logga ut</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from 'vue'
import { useAuthStore } from '@/stores/authStore'
import { NavigationItem } from '@/types/types'

const AuthStore = useAuthStore()

const props = defineProps({
  navigationItems: Array as () => NavigationItem[],
  drawer: Boolean
})

const drawer = computed(() => {
  return props.drawer
})

const emit = defineEmits(['update:drawer'])

const toggleDrawer = () => {
  emit('update:drawer', !props.drawer)
}

function signOut () {
  const updatedDrawer = !props.drawer
  emit('update:drawer', updatedDrawer)
  useAuthStore().setIsAuthenticated(false)
}
</script>
<style scoped>
.custom-button {
  text-transform: none !important;
  color: #000000 !important;
  text-decoration: none !important;
}
</style>
