import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/case',
    name: 'Ärenden & tjänster',
    component: () => import('@/views/CaseView.vue')
  },
  {
    path: '/case/:id',
    name: 'Ärende',
    component: () => import('@/views/CaseDetailView.vue')
  },
  {
    path: '/companyinfo',
    name: 'Företagets uppgifter',
    component: () => import('@/views/CompanyView.vue')
  },
  {
    path: '/delegate',
    name: 'Ombud',
    component: () => import('@/views/DelegateView.vue')
  },
  {
    path: '/estates',
    name: 'Fastigheter',
    component: () => import('@/views/EstateView.vue')
  },
  {
    path: '/estate/:id',
    name: 'Fastighet',
    component: () => import('@/views/EstateDetailView.vue')
  }, {
    path: '/permits',
    name: 'Beslut & tillstånd',
    component: () => import('@/views/PermitView.vue')
  },
  {
    path: '/service/:id',
    name: 'Tjänst',
    component: () => import('@/views/ServiceDetailView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
