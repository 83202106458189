import { defineStore } from 'pinia'

export const useEstatesStore = defineStore('estatesStore', {
  state: () => ({
    chosenRepresentative: '',
    estateID: '',
    estateLists: [] as any,
    estateLoadStatus: 'notLoaded'
  }),
  getters: {
    getEstatesLoadedState (): string {
      return this.estateLoadStatus === 'notLoaded' ? 'notLoaded' : 'loaded'
    },
    getEstateObjects (): any {
      return this.estateLists.find(
        (estate: any) => estate.identity === this.chosenRepresentative
      )
    },
    getChosenEstate (): any {
      return this.getEstateObjects && this.getEstateObjects.list.length > 0
        ? this.getEstateObjects.list.find(
          (estate: any) => estate.fastighetsbetechning === this.estateID
        )
        : undefined
    },
    getChosenEstateStatus (): string {
      const estateObject = this.estateLists.find(
        (c: { identity: string }) => c.identity === this.chosenRepresentative
      )

      return estateObject?.status || ''
    }
  },
  actions: {
    setDemoData () {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mockData = require(process.env.VUE_APP_MOCK_ESTATE)
      this.estateLists = mockData
      this.estateLoadStatus = 'loaded'
    },
    setRepresentative (representative: string) {
      this.chosenRepresentative = representative
    },
    setEstateID (id: string) {
      this.estateID = id
    },
    updateEstatesList (token: string, companies: string) {
      if (
        this.estateLoadStatus === 'loaded' ||
        this.estateLoadStatus === 'loading'
      ) {
        return
      }
      this.estateLoadStatus = 'loading'
      const promises: any[] = []
      companies.split(',').forEach((company: any) => {
        const url = process.env.ESTATES_API_URL as string
        const headers = {
          identity: company,
          Authorization: `bearer ${token}`,
          'x-origin': process.env.ORIGIN_ENV as string
        }
        const promise = fetch(url, {
          method: 'GET',
          headers: headers
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.json()
          })
          .then((data) => {
            this.estateLists.push({ identity: company, estates: data })
          })
          .catch((error) => {
            console.error('Error fetching data:', error)
          })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        this.estateLoadStatus = 'loaded'
      })
    }
  },
  persist: {
    storage: sessionStorage
  }
})
