import { defineStore } from 'pinia'

export interface timeLineStep {
  state: string;
  exception: boolean;
  dotColor: string;
  dotTextColor: string;
  dotIcon: string;
  caption: string;
}

interface permitItem {
  docType: string;
  description: string;
  issueDate: Date;
  typeDescription: string;
  fileName: string;
  fileID: string;
  data: string;
  friendlyCaseID: string;
  source: string;
}

interface caseItem {
  columns: any;
  raw: any;
  friendlyCaseID: string;
  description: string;
  caseType: string;
  status: string;
  created: Date;
  closed: Date;
  updated: Date;
  handlaggareName: string;
  handlaggarePhone: string;
  handlaggareEmail: string;
  source: string;
  initiatedBy: string;
  role: string;
  decisions: permitItem[];
  realestateName: string;
}
interface caseObject {
  identity: string;
  list: caseItem[];
  retryCount: number;
  status: string;
}

interface permitItemTypes {
  [key: string]: permitItem[];
}

export const useCaseStore = defineStore('caseStore', {
  state: () => ({
    chosenRepresentative: '',
    representativeType: '',
    caseID: '',
    caseType: '',
    caseSource: '',
    caseLoadStatus: 'notLoaded',
    caseLists: [] as caseObject[],
    searchOngoing: '',
    searchClosed: '',
    searchPermits: ''
  }),
  getters: {
    getPermitsByType (): permitItemTypes {
      if (this.caseID && Object.keys(this.getCaseObject).length > 0) {
        const permits = {} as permitItemTypes
        this.getCaseObject.decisions.map((p: permitItem) => {
          if (!permits[p.docType]) {
            permits[p.docType] = []
          }
          permits[p.docType].push(p)
          return p
        })
        return permits
      } else {
        return {} as permitItemTypes
      }
    },
    getCaseObject (): any {
      if (
        this.caseLoadStatus === 'loaded' ||
        this.caseLoadStatus === 'loadedWithErrors'
      ) {
        const representativeCases = this.caseLists.find(
          (c: caseObject) => c.identity === this.chosenRepresentative
        )

        if (representativeCases) {
          const chosenObject = representativeCases.list.find(
            (c: caseItem) => c.friendlyCaseID === this.caseID
          )

          return chosenObject || {}
        }
      }

      return {}
    },
    getChosenCaseStatus (): string {
      const caseObject = this.caseLists.find(
        (c: caseObject) => c.identity === this.chosenRepresentative
      )

      return caseObject?.status || ''
    },
    getChosenCaseList (): caseItem[] {
      if (
        !this.caseLists?.length ||
        !this.chosenRepresentative ||
        this.caseLoadStatus === 'notLoaded') {
        return []
      }

      const caseObject = this.caseLists.find(
        (c: caseObject) => c.identity === this.chosenRepresentative
      )

      return caseObject?.list || []
    },
    getStartpageSubset (): caseItem[] {
      return this.getChosenCaseList !== undefined &&
        this.getChosenCaseList.length > 0
        ? this.getChosenCaseList
          .sort((a: caseItem, b: caseItem) => {
            return (
              new Date(b.updated).getTime() - new Date(a.updated).getTime()
            )
          })
          .slice(0, 2)
        : []
    },
    getOngoingCases (): caseItem[] {
      if (this.getChosenCaseList.length > 0) {
        return this.getChosenCaseList.filter((caseItem: caseItem) => {
          return caseItem.status && caseItem.status !== 'Ärendet avslutat'
        })
      }

      return []
    },
    getClosedCases (): caseItem[] {
      if (this.getChosenCaseList.length > 0) {
        return this.getChosenCaseList.filter((caseItem: caseItem) => {
          return caseItem.status && caseItem.status === 'Ärendet avslutat'
        })
      }

      return []
    },
    getPermitsFromCases (): permitItem[] {
      const permits: permitItem[] = []

      if (this.getChosenCaseList !== undefined) {
        this.getChosenCaseList.forEach((chosenCase: caseItem) => {
          if (chosenCase.decisions.length > 0) {
            chosenCase.decisions.forEach((decision: permitItem) => {
              decision.friendlyCaseID = chosenCase.friendlyCaseID
              decision.source = chosenCase.source
              permits.push(decision)
            })
          }
        })
      }

      return permits
    },
    getTimelineSteps (): timeLineStep[] {
      const caseObject = this.getCaseObject

      if (!caseObject || Object.keys(caseObject).length === 0) {
        return []
      }

      const caseSource = caseObject.source.toUpperCase()
      const caseType = caseObject.caseType.toUpperCase()
      const initiatedBy = caseObject.initiatedBy
      const description = caseObject.description.toLowerCase()

      switch (caseSource) {
        case 'CASTOR':
          switch (caseType) {
            case 'BYGG':
              return [
                {
                  state: 'Ärendet mottaget',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Handläggare tilldelad',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Komplettering behövs',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Handläggning pågår',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Beslut fattat',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Byggfas',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Ärendet prövas av överinstans',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Beslut överklagat',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Ärendet avslutat',
                  exception: false
                } as timeLineStep
              ]
            case 'STR':
              return [
                {
                  state: 'Ärendet mottaget',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Handläggare tilldelad',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Komplettering behövs',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Handläggning pågår',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Beslut fattat',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Ärendet prövas av överinstans',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Beslut överklagat',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Ärendet avslutat',
                  exception: false
                } as timeLineStep
              ]
            case 'MIL':
              if (initiatedBy === 'User') {
                if (/^anmälan vattenverksamhet.*/.test(description)) {
                  return [
                    {
                      state: 'Ärendet mottaget',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Handläggare tilldelad',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Komplettering behövs',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Handläggning pågår',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Beslut fattat',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Ärendet prövas av överinstans',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Beslut överklagat',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Ärendet avslutat',
                      exception: false
                    } as timeLineStep
                  ]
                } else {
                  return [
                    {
                      state: 'Ärendet mottaget',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Komplettering behövs',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Handläggning pågår',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Beslut fattat',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Ärendet prövas av överinstans',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Beslut överklagat',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Ärendet avslutat',
                      exception: false
                    } as timeLineStep
                  ]
                }
              } else {
                return [
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Tillsynsbesök bokat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Ärendet prövas av överinstans',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              }
            case 'HÄL':
              if (initiatedBy === 'User') {
                return [
                  {
                    state: 'Ärendet mottaget',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Ärendet prövas av överinstans',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              } else {
                return [
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Tillsynsbesök bokat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Ärendet prövas av överinstans',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              }
            case 'LIV':
              if (initiatedBy === 'User') {
                return [
                  {
                    state: 'Ärendet mottaget',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Ärendet prövas av överinstans',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              } else {
                if (/^kontroll.*/.test(description)) {
                  return [
                    {
                      state: 'Komplettering behövs',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Handläggning pågår',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Beslut fattat',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Ärendet prövas av överinstans',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Beslut överklagat',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Ärendet avslutat',
                      exception: false
                    } as timeLineStep
                  ]
                } else {
                  return [
                    {
                      state: 'Komplettering behövs',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Handläggning pågår',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Beslut fattat',
                      exception: false
                    } as timeLineStep,
                    {
                      state: 'Ärendet prövas av överinstans',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Beslut överklagat',
                      exception: true
                    } as timeLineStep,
                    {
                      state: 'Ärendet avslutat',
                      exception: false
                    } as timeLineStep
                  ]
                }
              }
            case 'MEA':
              if (initiatedBy === 'User') {
                return [
                  {
                    state: 'Ärendet mottaget',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Avvaktar annan prövning',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              } else {
                return [
                  {
                    state: 'Komplettering behövs',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Tillsynsbesök bokat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Handläggning pågår',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Beslut fattat',
                    exception: false
                  } as timeLineStep,
                  {
                    state: 'Avvaktar annan prövning',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Beslut överklagat',
                    exception: true
                  } as timeLineStep,
                  {
                    state: 'Ärendet avslutat',
                    exception: false
                  } as timeLineStep
                ]
              }
            case 'KÖLD':
              return [
                {
                  state: 'Ärendet mottaget',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Komplettering behövs',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Handläggning pågår',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Beslut fattat',
                  exception: false
                } as timeLineStep,
                {
                  state: 'Ärendet prövas av överinstans',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Beslut överklagat',
                  exception: true
                } as timeLineStep,
                {
                  state: 'Ärendet avslutat',
                  exception: false
                } as timeLineStep
              ]
            default:
              return [
                {
                  state: 'saknar ärendedefiniton - Castor',
                  exception: false
                } as timeLineStep
              ]
          }
        case 'ALKT':
          if (initiatedBy === 'User') {
            return [
              {
                state: 'Ärendet mottaget',
                exception: false
              } as timeLineStep,
              {
                state: 'Handläggare tilldelad',
                exception: false
              } as timeLineStep,
              {
                state: 'Komplettering behövs',
                exception: true
              } as timeLineStep,
              {
                state: 'Handläggning pågår',
                exception: false
              } as timeLineStep,
              {
                state: 'Beslut fattat',
                exception: false
              } as timeLineStep,
              {
                state: 'Ärendet avslutat',
                exception: false
              } as timeLineStep
            ]
          } else {
            return [
              {
                state: 'saknar ärendedefiniton - Alkt',
                exception: false
              } as timeLineStep
            ]
          }
        case 'OL2':
          if (initiatedBy === 'User') {
            return [
              {
                state: 'Ärendet mottaget',
                exception: false
              } as timeLineStep,
              {
                state: 'Handläggare tilldelad',
                exception: false
              } as timeLineStep,
              {
                state: 'Komplettering behövs',
                exception: true
              } as timeLineStep,
              {
                state: 'Handläggning pågår',
                exception: false
              } as timeLineStep,
              {
                state: 'Beslut fattat',
                exception: false
              } as timeLineStep,
              {
                state: 'Ärendet avslutat',
                exception: false
              } as timeLineStep
            ]
          } else {
            return [
              {
                state: 'saknar ärendedefiniton - OL2',
                exception: false
              } as timeLineStep
            ]
          }
        default:
          return []
      }
    }
  },
  actions: {
    setDemoData () {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mockData = require(process.env.VUE_APP_MOCK_CASE)
      this.caseLists = mockData
      this.caseLoadStatus = 'loaded'
    },
    setRepresentative (id: string, objectType: string) {
      this.chosenRepresentative = id
      this.representativeType = objectType
    },
    setCaseID (caseID: string) {
      this.caseID = caseID
    }
  },
  persist: {
    storage: sessionStorage
  }
})
